import { useEffect, useRef, useState } from "react";

import { useMediaIsMobile } from "@/src/hooks";

export function useToggleSearch() {
  const [open, setOpen] = useState<boolean>(false);
  const isMobile = useMediaIsMobile();

  function handleToggleOpen() {
    setOpen(!open);
  }

  const refSearch = useRef<HTMLFormElement>(null);

  const handleClickOutside = (event) => {
    if (refSearch.current !== null) {
      if (!refSearch.current.contains(event.target)) {
        setOpen(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
  }, [refSearch]);

  return { open, refSearch, isMobile, handleToggleOpen };
}

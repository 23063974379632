import { SVGSkeleton } from "@/components/ui/feedback/skeleton";

export const NotificationsSkeleton = () => (
  <>
    <div className="relative inline-flex items-center justify-center w-10 h-10 transition-colors">
      <SVGSkeleton className="w-[24px] h-[24px]" />
    </div>
  </>
);

NotificationsSkeleton.displayName = "Notifications.Skeleton";

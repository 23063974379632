import { VariantProps } from "class-variance-authority";

import { Skeleton } from "@/components/ui/feedback/skeleton";
import boxVariants from "@/components/utils/variants/box.variant";
import { cn } from "@/src/lib/utils";

interface ButtonSkeletonProps
  extends Pick<React.HTMLAttributes<HTMLButtonElement>, "className">,
    VariantProps<typeof boxVariants> {}

export function ButtonSkeleton({ className, size }: ButtonSkeletonProps) {
  return <Skeleton className={cn({ size }, "w-full", className)} />;
}

import { useRouter } from "next/navigation";
import { useQueryClient } from "@tanstack/react-query";
import { Trash2 } from "lucide-react";

import {
  getNotificationListQueryKey,
  useNotificationPartialUpdate,
} from "architecture/src/shared/api/endpoints/notification/notification";

import { badgeVariants } from "@/components/ui/data-display/badge";
import { Typography } from "@/components/ui/data-display/typography";
import { Stack } from "@/components/ui/layout/stack";
import { cn, contentRedirect, stringDateToLocaleString } from "@/src/lib/utils";
import { Button } from "@/components/ui/inputs/button";
import { NotificationResponseData } from "architecture/src/shared/domain/models";

type NotificationsItemProps = NotificationResponseData & {
  page?: number;
  page_size?: number;
  closeMenu(): void;
};

export function NotificationsItem({
  id = 0,
  updateAt,
  read,
  text,
  page,
  page_size,
  objectId,
  contentType,
  contentObject,
  closeMenu,
}: NotificationsItemProps) {
  const router = useRouter();
  const queryClient = useQueryClient();
  const { mutate: updateRead, isPending } = useNotificationPartialUpdate({
    mutation: {
      onSuccess: () => {
        // List
        queryClient.invalidateQueries({
          queryKey: getNotificationListQueryKey({ page, page_size }),
        });
        // Total unread count
        queryClient.invalidateQueries({
          queryKey: getNotificationListQueryKey({}),
        });
      },
    },
  });

  const handleRead = (redirect: boolean) => {
    updateRead({ id: id.toString() });
    if (redirect) {
      const contentRedirect1 = contentRedirect({
        contentType,
        contentObject: contentObject,
        id: objectId.toString(),
      });
      if (contentRedirect1.openNewTab) {
        window.open(contentRedirect1.url, "_ blank");
      } else {
        router.push(contentRedirect1.url);
      }
      closeMenu();
    }
  };

  return (
    <Stack {...(!read && {})} className="cursor-pointer group flex-1">
      <Stack direction="row" justify="spaceBetween" align="center">
        <Stack onClick={() => handleRead(true)}>
          {updateAt && (
            <Typography variant="caption" color="muted">
              {stringDateToLocaleString(updateAt)}
            </Typography>
          )}
          <Stack className="relative">
            {!read && (
              <div
                className={cn(
                  badgeVariants({ variant: "destructive" }),
                  "w-2 h-2 rounded-full -ml-3 absolute p-0 top-1/2 -translate-y-1/2 transition-opacity",
                  isPending && "opacity-50",
                )}
              />
            )}
            <Typography
              variant="body2"
              weight="semibold"
              className="flex line-clamp-1 group-hover:underline"
            >
              {text}
            </Typography>
          </Stack>
        </Stack>
        <Button variant="icon" onClick={() => handleRead(false)}>
          <Trash2 width={20} className="text-primary" />
        </Button>
      </Stack>
    </Stack>
  );
}

NotificationsItem.Skeleton = "NotificationsItem.Skeleton";
